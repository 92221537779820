<template>
  <restaurants-module-component></restaurants-module-component>
</template>

<script>
import RestaurantsModuleComponent from "@/components/admin/modules/RestaurantsModuleComponent";

export default {
  name: "RestaurantsModule",
  title: "Gestión de Restaurantes | Baja California Health Tourism",
  components: { RestaurantsModuleComponent },
};
</script>

<style scoped></style>
